import React from "react";

const Component = ({ title, description }) => {
  return (
    <div className="commitment">
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  );
};

export default Component;
