import React, { useState } from "react";
import "./register.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Register() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    companyName: "",
    email: "",
    contact: "",
    address: "",
    imageUrl: null,
    role: "owner",
    document: [],
    employeeLength: 10,
    password: "",
    companyCode: "",
    fcmToken: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      imageUrl: file,
    }));
  };

  const validateForm = () => {
    const { companyName, email, contact, address, password } = formData;
    if (!companyName || !email || !contact || !address || !password) {
      return false;
    }
    return true;
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (!validateForm()) {
  //     toast.info("Please fill in all required fields.");
  //     return;
  //   }

  //   const formDataToSend = new FormData();
  //   for (const key in formData) {
  //     formDataToSend.append(key, formData[key]);
  //   }

  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_BASE_URL}/admin/create-Company`,
  //       formDataToSend,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );

  //     toast.success(response.data.msg || "Company registered successfully.");

  //     // Delay navigation slightly after toast
  //     setTimeout(() => {
  //       navigate("/login");
  //     }, 3000); // Adjust delay as needed
  //   } catch (error) {
  //     const errorMsg = error.response ? error.response.data : error.message;
  //     toast.error(`Registration failed: ${errorMsg}`);
  //     console.error("Registration Error:", errorMsg);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.info("Please fill in all required fields.");
      return;
    }

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/admin/create-Company`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Show toast
      toast.success(response.data.msg || "Company registered successfully.");

      await new Promise((resolve) => setTimeout(resolve, 4000));
      navigate("/login");
    } catch (error) {
      toast.error(`Registration failed`);
    }
  };

  return (
    <>
      <div className="register">
        <ToastContainer />
        <div className="formContainer">
          <form className="form-container" onSubmit={handleSubmit}>
            <h1>Create Company Account</h1>

            <div className="form-group">
              <div className="form-group-input">
                <label>Company Name</label>
                <input
                  name="companyName"
                  type="text"
                  placeholder="Enter Your Company Name..."
                  value={formData.companyName}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group-input">
                <label>Email</label>
                <input
                  name="email"
                  type="email"
                  placeholder="Enter Your Email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-group">
              <div className="form-group-input">
                <label>Phone Number</label>
                <input
                  name="contact"
                  type="number"
                  placeholder="Phone Number"
                  value={formData.contact}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group-input">
                <label>Address</label>
                <input
                  name="address"
                  type="text"
                  placeholder="Address"
                  value={formData.address}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-group">
              <div className="form-group-input">
                <label>Photo</label>
                <input
                  name="imageUrl"
                  type="file"
                  onChange={handleFileChange}
                />
              </div>

              <div className="form-group-input">
                <label>Password</label>
                <input
                  name="password"
                  type="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-btn">
              <button className="register-button" type="submit">
                Register
              </button>
              <Link to="/login" className="login-link">
                Do you have an account?
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Register;
