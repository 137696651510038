import React from "react";
import "./Logout.css";
import { useNavigate } from "react-router-dom";

function Logout() {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear authentication data from localStorage
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("isAuthenticated");

    // Redirect to login page
    navigate("/login");
  };

  return (
    <button className="logout-button" onClick={handleLogout}>
      Logout
    </button>
  );
}

export default Logout;

// import React, { useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import { AuthContext } from "../../Contex/AuthContext"; // Import AuthContext
// import "./Logout.css";

// function Logout() {
//   const { logout } = useContext(AuthContext); // Use AuthContext
//   const navigate = useNavigate();

//   const handleLogout = () => {
//     if (window.confirm("Do you really want to log out?")) {
//       logout(); // Use logout method from AuthContext

//       // Redirect to login page after successful logout
//       navigate("/login");
//     }
//   };

//   return (
//     <button onClick={handleLogout} className="logout-button">
//       Logout
//     </button>
//   );
// }

// export default Logout;
