import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Navbar from "./Header/Navbar.js";
import HeroSection from "./Hero-section/HeroSection.js";
import CommitmentSection from "./CommitmentSection/CommitSection.js";
import Footer from "./Footer/footer.js";

function MainPage() {
  return (
    <div className="App">
      {/* <Navbar /> */}
      <HeroSection />
      <CommitmentSection />
      {/* <Footer /> */}
    </div>
  );
}

export default MainPage;
