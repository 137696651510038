import React from "react";
import "./Features.css";
import { Link, useNavigate } from "react-router-dom";
import Group from "../../../Assets/Group.png";
import { FaGooglePlay } from "react-icons/fa";
import { FaApple } from "react-icons/fa";

function Features() {
  return (
    <div>
      <div className="featurecontainer">
        <div className="featurecontent">
          <h1>Divine features Page</h1>
          <p>
            At Divine Support Care, our vision is to create a world where people
            with disability are valued, respected, and empowered to live their
            best lives. We believe that everyone deserves the opportunity to
            reach their full potential, regardless of their abilities.
          </p>
          {/* <button className="featurebutton">Explore</button> */}
        </div>
      </div>
      <section className="featureInvoice">
        <div className="featureInvoice-container">
          <h1>Invoicing</h1>
          <p>Generate and send professional invoices with just a few clicks.</p>
          <div className="featureInvoice-cards-container">
            <div className="featureInvoice-card">
              <h2>Automated Invoicing</h2>
              <p>Personalize your invoices with your own branding and style.</p>
            </div>
            <div className="featureInvoice-card">
              <h2>Customizable Templates</h2>
              <p>Personalize your invoices with your own branding and style.</p>
            </div>
            <div className="featureInvoice-card">
              <h2>Payment Tracking</h2>
              <p>
                Keep track of all your payments and easily follow up on
                outstanding invoices.
              </p>
            </div>
            <div className="featureInvoice-card">
              <h2>Invoice Reminders</h2>
              <p>Send automated reminders to clients for overdue payments.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="featureRoster">
        <div className="featureInvoice-container">
          <h1>Rostering</h1>
          <p>Generate and send professional invoices with just a few clicks.</p>
          <div className="featureRoster-cards-container">
            <div className="featureRoster-card">
              <h2>Shift Management</h2>
              <p>
                Easily create, assign, and manage shifts for your employees.
              </p>
            </div>
            <div className="featureRoster-card">
              <h2>Availability Tracking</h2>
              <p>
                Keep track of employee availability and ensure optimal
                scheduling.
              </p>
            </div>
            <div className="featureRoster-card">
              <h2>Automated Reminders</h2>
              <p>
                Receive automated reminders for upcoming shifts and schedule
                changes.
              </p>
            </div>
            <div className="featureRoster-card">
              <h2>Conflict Resolution</h2>
              <p>
                Resolve scheduling conflicts with ease and ensure smooth
                operations.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="featureDetails">
        <div className="featureDetails-container">
          <h1>Divine App Details</h1>
          <p>Generate and send professional invoices with just a few clicks.</p>
          <div className="featureDetails-cards-container">
            <div className="featureDetails-card">
              <h2>Employee Management</h2>
              <p>
                Efficiently manage employee information, schedules, and tasks.
              </p>
            </div>
            <div className="featureDetails-card">
              <h2>HR Management</h2>
              <p>
                Streamline HR processes, such as onboarding, leave management,
                and performance evaluations.
              </p>
            </div>
            <div className="featureDetails-card">
              <h2>Invoicing</h2>
              <p>
                Simplify invoicing and billing processes for seamless financial
                management.
              </p>
            </div>
            <div className="featureDetails-card">
              <h2>Rostering</h2>
              <p>
                Easily create and manage employee schedules for optimized
                workforce management.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="featureImage-container">
        <img className="featureImage" src={Group} alt="image" />
      </div>
      <div className="feature-container">
        <p>Get Best experience </p>
        <div>
          <button>Download Now</button>
        </div>

        <div className="links-buttons">
          <div>
            <a
              className="play-store-button"
              href="https://play.google.com/store/apps/details?id=com.spidertechnology.divineenterprise&hl=en&gl=US"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGooglePlay className="play-store-icon" />
              <div className="play-store-text">
                <span>Get it on</span>
                <span>Google Play</span>
              </div>
            </a>
          </div>
          <div>
            <a
              className="app-store-button"
              href="https://play.google.com/store/apps/dev?id=6910989262027202013&hl=en&gl=US"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaApple className="app-store-icon" />
              <div className="app-store-text">
                <span>Download on the</span>
                <span>App Store</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
