import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import MainPage from "./Components/MainPage";
import Features from "./Components/Pages/Feature/Features";
import AboutUs from "./Components/Pages/AboutUs/AboutUs";
import Price from "./Components/Pages/Price/Price";
import Solution from "./Components/Pages/Solution/Solution.js";
import OutLet from "./Components/OutLet.js";
import Explore from "./Components/Pages/Explore/Explore.js";
import "./App.css";
import Login from "./Components/login/login.js";
import Register from "./Components/Register/register.js";
import AccountDetails from "./Components/companydetailPage/AccountDetails.js";
import AuthRoute from "./auth/AuthRoute.js";
import { AuthProvider } from "./Contex/AuthContext.js";

function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<OutLet />}>
              <Route path="/" element={<MainPage />} />
              <Route path="/feature" element={<Features />} />
              <Route path="/about" element={<AboutUs />} />
              {/* <Route path="/price" element={<Price />} /> */}
              <Route path="/solution" element={<Solution />} />
              <Route path="/explore" element={<Explore />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route
                path="/companydetail"
                element={
                  <AuthRoute>
                    <AccountDetails />
                  </AuthRoute>
                }
              />
            </Route>
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
