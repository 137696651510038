import React from "react";
import Component from "./Component";
import "./CommitSection.css";
import { Link, useNavigate } from "react-router-dom";

const CommitmentSection = () => {
  return (
    <section className="commitments">
      <h1 className="commitments-heading">OUR COMMITMENTS</h1>
      <div className="commitments-container">
        <Component
          title="Empowerment Through Inclusivity"
          description="We strive to create a society that recognizes and values the abilities of every individual."
        />
        <Component
          title="Resource Hub"
          description=" Access a wealth of resources, information, and tools tailored to support and enhance the lives of individuals with disabilities."
        />
        <Component
          title="Inspiring Stories"
          description=" Access a wealth of resources, information, and tools tailored to support and enhance the lives of individuals with disabilities."
        />
      </div>
      <div className="explore-container">
        <p>Let's Explore</p>
        <div>
          <Link to="/explore">
            <button>Click to Explore</button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default CommitmentSection;
