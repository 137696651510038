import React, { useState, useContext } from "react";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../Contex/AuthContext"; // Import AuthContext

function Login() {
  const { login } = useContext(AuthContext); // Use AuthContext
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    role: "Owner", // Default role
  });
  const [error, setError] = useState(null); // State to store error messages

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/compnay/login`, // Corrected endpoint
        loginData
      );

      if (response.status === 200) {
        // Use login method from AuthContext
        login(response.data.token, response.data.role);

        // Redirect to company details
        navigate("/companydetail");
      } else {
        setError("Login failed. Please try again.");
      }
    } catch (error) {
      console.error(
        "Login Error:",
        error.response ? error.response.data : error.message
      );
      setError("Login failed. Please check your credentials and try again.");
    }
  };

  return (
    <div className="login">
      <div className="formContainer">
        <form onSubmit={handleSubmit}>
          <h1>Welcome back</h1>
          {error && <div className="error-message">{error}</div>}{" "}
          {/* Display error message */}
          <input
            name="email"
            type="text"
            placeholder="Email"
            value={loginData.email}
            onChange={handleChange}
          />
          <input
            name="password"
            type="password"
            placeholder="Password"
            value={loginData.password}
            onChange={handleChange}
          />
          <select name="role" value={loginData.role} onChange={handleChange}>
            <option value="Owner">Owner</option>
            <option value="Admin">Admin</option>
          </select>
          <button type="submit">Login</button>
          <Link to="/register">{"Don't"} have an account?</Link>
        </form>
      </div>
    </div>
  );
}

export default Login;
