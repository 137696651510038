import React from "react";
import pricing from "../../../Assets/pricing.png";
import "./Price.css";

function Price() {
  const plans = [
    {
      name: "Premium Plan",
      price: "$29.99/month",
      features: [
        "Unlimited job applications.",
        "Enhanced chat capabilities.",
        "Priority job alerts.",
        "Advanced profile analytics.",
      ],
      pricing: [
        "Higher monthly subscription fee.",
        "Annual subscription option for additional savings.",
      ],
    },
    {
      name: "Basic Plan",
      price: "$19.99/month",
      features: [
        "Access to the core features of the Divine app.",
        "Limited job applications per month.",
        "Basic chat functionality.",
        "Profile customization.",
      ],
      pricing: [
        "Monthly subscription fee.",
        "Option for annual billing with a discounted rate.",
      ],
    },
    {
      name: "Business Plan",
      price: "$29.99/month",
      features: [
        "Dedicated account manager.",
        "Customized branding on profiles.",
        "Advanced reporting and analytics.",
        "Priority customer support.",
      ],
      pricing: [
        "Custom pricing based on the organization's size and requirements.",
        "Tiered pricing for scalability.",
      ],
    },
  ];

  return (
    <>
      <div className="main-container">
        <div className="left-container">
          <h1>PRICING PLANS</h1>
          <p>
            Unlock Divine's Potential with Flexible Pricing Plans! Discover the
            perfect plan to elevate your Divine experience. From the essential
            features of our Basic Plan to the premium perks of Divine Pro and
            the tailored solutions in Divine Enterprise, we have plans to suit
            every need. Enjoy transparent pricing, upgrade or downgrade at your
            convenience, and explore the freedom of choice with our Freemium
            Model.
          </p>
        </div>
        <div className="right-container">
          <img src={pricing} alt="Pricing Image" />
        </div>
      </div>

      <div>
        <p>{plans.name}</p>
        <div className="plan-cards">
          {plans.map((plan, index) => (
            <div className="plan-card" key={index}>
              <div className="plan-name">{plan.name}</div>
              <div className="plan-details">
                <div className="plan-price">{plan.price}</div>
                <ul className="plan-features">
                  {plan.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
                <div className="plan-pricing">
                  <h4>Pricing Model:</h4>
                  <ul>
                    {plan.pricing.map((pricing, index) => (
                      <li key={index}>{pricing}</li>
                    ))}
                  </ul>
                </div>
                <button className="plan-button">Select Plan</button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="priceLast-con">
        <div>
          <p className="priceLast-heading">Community Engagement</p>
        </div>

        <div>
          <p className="priceLast-para">
            Feedback Mechanism: Encourage users to provide feedback on pricing
            and features. Community Forums: Foster a community where users can
            discuss their experiences and share tips. Remember to continuously
            monitor user feedback, market trends, and competitor pricing to make
            data-driven adjustments to the Divine app's pricing strategy.
            Regularly evaluate the effectiveness of the pricing model in meeting
            both user expectations and the business's financial goals.
          </p>
        </div>
      </div>
    </>
  );
}

export default Price;
