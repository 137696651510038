import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./AccountDetails.css";
import Logout from "../Logout/Logout";
import { AuthContext } from "../../Contex/AuthContext";
import axios from "axios";

const AccountDetails = () => {
  const { isAuthenticated, logout } = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [account, setAccount] = useState({});
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAccountDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        console.log("🚀 ~ fetchAccountDetails ~ token:", token);

        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/compnay/get-profile`,
          {
            headers: {
              "x-auth-token": token,
            },
          }
        );

        // Parse and set the account details
        const data = await response.data.user;
        console.log("🚀 ~ fetchAccountDetails ~ data:", data);
        setAccount(data);
      } catch (error) {
        console.error("Error fetching account details:", error);
      }
    };

    fetchAccountDetails();
  }, []);

  const handleDeleteAccount = () => {
    setShowDeletePopup(true);
  };

  const confirmDeleteAccount = async () => {
    try {
      // Call API to delete the account using axios
      await axios.delete(`/compnay/profile`, {
        data: {
          email,
          password,
        },
      });

      setShowDeletePopup(false);
      setShowSuccessPopup(true);

      // Navigate to the login page after showing the success popup
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  const cancelDeleteAccount = () => {
    setShowDeletePopup(false);
  };

  return (
    <div className="account-details-container">
      <Logout />
      <h1>Company Account Details</h1>
      {account ? (
        <>
          <div className="account-details">
            {/* <img src={account.image} alt="Account" className="account-image" /> */}
            <p>
              <strong>Name:</strong> {account.companyName}
            </p>
            <p>
              <strong>Code:</strong> {account.companyCode}
            </p>
            <p>
              <strong>Role:</strong> {account.role}
            </p>
            <p>
              <strong>Contact:</strong> {account.contact}
            </p>
            <p>
              <strong>Address:</strong> {account.address}
            </p>
          </div>
          <button className="delete-button" onClick={handleDeleteAccount}>
            Delete Account
          </button>
        </>
      ) : (
        <p>Loading account details...</p>
      )}

      {/* Delete Confirmation Popup */}
      {showDeletePopup && (
        <div className="popup">
          <div className="popup-content">
            <p>Do you want to delete your account?</p>
            <input
              className="popupInput"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              className="popupInput"
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button className="confirm-button" onClick={confirmDeleteAccount}>
              Confirm
            </button>
            <button className="cancel-button" onClick={cancelDeleteAccount}>
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* Success Popup */}
      {showSuccessPopup && (
        <div className="popup">
          <div className="popup-content">
            <p>Your Account Deleted Successfully</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountDetails;
