import React from "react";
import "./Solution.css";
import solution1 from "../../../Assets/solution1.png";

function Solution() {
  return (
    <div>
      <div className="solutioncontainer">
        <div className="solutioncontent">
          <h1>Modern Solution</h1>
          <p>
            Divine Support Care offers a variety of quality support services to
            people with a disability from young children to the elderly.
          </p>
        </div>
      </div>
      <div className="solution-para-container">
        <div>
          <p className="solution-heading">Supporting People</p>
        </div>

        <div>
          <p className="solution-para">
            Feedback Mechanism: Encourage users to provide feedback on pricing
            and features. Community Forums: Foster a community where users can
            discuss their experiences and share tips. Remember to continuously
            monitor user feedback, market trends, and competitor pricing to make
            data-driven adjustments to the Divine app's pricing strategy.
            Regularly evaluate the effectiveness of the pricing model in meeting
            both user expectations and the business's financial goals.
          </p>
        </div>
      </div>

      <div className="solutionmain-container">
        <div className="solutionleft-container">
          <h1>Solutions Plans</h1>
          <p>
            Unlock Divine's Potential with Flexible Pricing Plans! Discover the
            perfect plan to elevate your Divine experience. From the essential
            features of our Basic Plan to the premium perks of Divine Pro and
            the tailored solutions in Divine Enterprise, we have plans to suit
            every need. Enjoy transparent pricing, upgrade or downgrade at your
            convenience, and explore the freedom of choice with our Freemium
            Model.
          </p>
        </div>
        <div className="solutionright-container">
          <img src={solution1} alt="Pricing Image" />
        </div>
      </div>
    </div>
  );
}

export default Solution;
