import React, { useState } from "react";
import { FiMenu } from "react-icons/fi"; // Hamburger Icon
import "./Navbar.css";
import logo from "../../Assets/logo.png";
import { Link, useNavigate } from "react-router-dom";
import Logout from "../Logout/Logout";

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);
  const [activeItem, setActiveItem] = useState("home");

  const handleItemClick = (item) => {
    setActiveItem(item);
    setIsActive(false); // Close menu after clicking an item
  };

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const navigate = useNavigate();
  const navigateHandler = () => {
    navigate("/");
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={logo} alt="Logo" onClick={navigateHandler} />
      </div>

      <div className={`navbar-menu ${isActive ? "active" : ""}`}>
        <ul>
          <li
            className={activeItem === "home" ? "active" : ""}
            onClick={() => handleItemClick("home")}
          >
            <Link to="/">Home</Link>
          </li>
          <li
            className={activeItem === "features" ? "active" : ""}
            onClick={() => handleItemClick("features")}
          >
            <Link to="/feature">Features</Link>
          </li>
          {/* <li
            className={activeItem === "price" ? "active" : ""}
            onClick={() => handleItemClick("price")}
          >
            <Link to="/price">Price</Link>
          </li> */}
          <li
            className={activeItem === "solution" ? "active" : ""}
            onClick={() => handleItemClick("solution")}
          >
            <Link to="/solution">Solution</Link>
          </li>
          <li
            className={activeItem === "about" ? "active" : ""}
            onClick={() => handleItemClick("about")}
          >
            <Link to="/about">About Us</Link>
          </li>
        </ul>
      </div>

      <div className="navbar-buttons">
        <Link to="/login" className="explore-btn">
          Login{" "}
        </Link>
        <Link to="/register" className="explore-btn">
          {" "}
          Register
        </Link>
      </div>

      <div className="navbar-toggle" onClick={toggleMenu}>
        <FiMenu />
      </div>
    </nav>
  );
};

export default Navbar;

// import React, { useContext, useState } from "react";
// import { FiMenu } from "react-icons/fi"; // Hamburger Icon
// import "./Navbar.css";
// import logo from "../../Assets/logo.png";
// import { Link, useNavigate } from "react-router-dom";
// import { AuthContext } from "../../Contex/AuthContext"; // Import AuthContext

// const Navbar = () => {
//   const [isActive, setIsActive] = useState(false);
//   const [activeItem, setActiveItem] = useState("home");
//   const { isAuthenticated, logout } = useContext(AuthContext); // Use AuthContext
//   const navigate = useNavigate();

//   const handleItemClick = (item) => {
//     setActiveItem(item);
//     setIsActive(false); // Close menu after clicking an item
//   };

//   const toggleMenu = () => {
//     setIsActive(!isActive);
//   };

//   const navigateHandler = () => {
//     navigate("/");
//   };

//   return (
//     <nav className="navbar">
//       <div className="navbar-logo">
//         <img src={logo} alt="Logo" onClick={navigateHandler} />
//       </div>

//       <div className={`navbar-menu ${isActive ? "active" : ""}`}>
//         <ul>
//           <li
//             className={activeItem === "home" ? "active" : ""}
//             onClick={() => handleItemClick("home")}
//           >
//             <Link to="/">Home</Link>
//           </li>
//           <li
//             className={activeItem === "features" ? "active" : ""}
//             onClick={() => handleItemClick("features")}
//           >
//             <Link to="/feature">Features</Link>
//           </li>
//           <li
//             className={activeItem === "solution" ? "active" : ""}
//             onClick={() => handleItemClick("solution")}
//           >
//             <Link to="/solution">Solution</Link>
//           </li>
//           <li
//             className={activeItem === "about" ? "active" : ""}
//             onClick={() => handleItemClick("about")}
//           >
//             <Link to="/about">About Us</Link>
//           </li>
//         </ul>
//       </div>

//       <div className="navbar-buttons">
//         {!isAuthenticated ? (
//           <>
//             <Link to="/login" className="explore-btn">
//               Login
//             </Link>
//             <Link to="/register" className="explore-btn">
//               Register
//             </Link>
//           </>
//         ) : (
//           <button onClick={logout} className="explore-btn">
//             Logout
//           </button>
//         )}
//       </div>

//       <div className="navbar-toggle" onClick={toggleMenu}>
//         <FiMenu />
//       </div>
//     </nav>
//   );
// };

// export default Navbar;
