import React from "react";
import "./HeroSection.css";
import herobg from "../../Assets/herobg.png";

const HeroSection = () => {
  return (
    <div className="hero-container">
      <div className="hero-left">
        <img
          src={herobg}
          alt="Divine Support Care"
          className="hero-image"
        />
      </div>
      <div className="hero-right">
        <h1 className="hero-heading">Divine Support Care</h1>
        <p className="hero-paragraph">
          At Divine, we believe in creating an inclusive world where every
          individual, regardless of their abilities, is celebrated for their
          unique strengths. Our mission is to empower and support people with
          disabilities, fostering a community that embraces diversity and
          promotes equal opportunities.
        </p>
      </div>
    </div>
  );
};

export default HeroSection;
