import React, { useState } from "react";
import "./AboutUs.css";
import About1 from "../../../Assets/About1.png";
import { Link, useNavigate } from "react-router-dom";
import Aboutpic1 from "../../../Assets/Aboutpic1.png";
import Aboutpic2 from "../../../Assets/Aboutpic2.png";
import Aboutpic3 from "../../../Assets/Aboutpic3.png";
import Aboutpic4 from "../../../Assets/Aboutpic4.png";
import Aboutpic5 from "../../../Assets/Aboutpic5.png";
import Aboutpic6 from "../../../Assets/Aboutpic6.png";

import Profile1 from "../../../Assets/Profile1.png";
import Profile2 from "../../../Assets/Profile2.png";
import Profile3 from "../../../Assets/Profile3.png";
import Default from "../../../Assets/Default.png";

function AboutUs() {
  // data for GALLERY
  const [showAll, setShowAll] = useState(false);
  const images = [
    // ARRAY RELATED TO GALLLERY SECTION
    Aboutpic1,
    Aboutpic2,
    Aboutpic3,
    Aboutpic4,
    Aboutpic5,
    Aboutpic6,
    Aboutpic1,
    Aboutpic2,
    Aboutpic3,
    Aboutpic4,
  ];

  const toggleShowMore = () => {
    // FUCNTION RELATED TO GALLLERY SECTION
    setShowAll(!showAll);
  };

  // data for team
  const teamMembers = [
    {
      id: 1,
      name: "Kashif Mushtaq",
      role: "Project Manager",
      image: Default,
    },
    {
      id: 2,
      name: "Asad Javed",
      role: "CEO",
      image: Default, // Sample image URL
    },
    {
      id: 3,
      name: "Claude Bizumuremyi",
      role: "CEO",
      image: Default, // Sample image URL
    },
    // Add more team members as needed
  ];

  // data for REview
  const reviews = [
    {
      id: 1,
      stars: 4,
      description: "Great service! Will definitely come back again.",
      image: Profile3,
      name: "Michael Johnson",
    },
    {
      id: 2,
      stars: 5,
      description: "Amazing experience! Highly recommended.",
      image: Profile1,
      name: "Jehni Smith",
    },
    {
      id: 3,
      stars: 4,
      description: "Very satisfied with the product.",
      image: Profile2,
      name: "Alice Max ",
    },
    // Add more reviews as needed
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? reviews.length - 1 : prevSlide - 1
    );
  };

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === reviews.length - 1 ? 0 : prevSlide + 1
    );
  };

  return (
    <div>
      <div className="Aboutcontainer">
        <div className="Aboutcontent">
          <h1>About Us</h1>
          <p>
            We are dedicated to giving each of our participants a secure,
            encouraging, and welcoming atmosphere. Our facilities are easily
            accessible, and our staff is qualified to offer individuals with
            special needs the support and assistance they need to fully engage
            in all events.
          </p>
        </div>
      </div>

      <div className="About2-container">
        <div className="About2-left">
          <img
            src={About1}
            alt="Welcome to Divine Support Care"
            className="About2-image"
          />
        </div>
        <div className="About2-right">
          <h1 className="About2-heading">Divine Support Care</h1>
          <p className="About2-paragraph">
            At Divine, we believe in creating an inclusive world where every
            individual, regardless of their abilities, is celebrated for their
            unique strengths. Our mission is to empower and support people with
            disabilities, fostering a community that embraces diversity and
            promotes equal opportunities.
          </p>
          <div>
            <Link to="/">
              <button>See all Services</button>
            </Link>
          </div>
        </div>
      </div>

      <div className="Gallery-main-container">
        <h1 className="main-heading">
          {" "}
          <span className="gallerySpan">Divine</span> Photo Showcase
        </h1>
        <div className="photo-gallery">
          {images.slice(0, showAll ? images.length : 6).map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Image ${index + 1}`}
              className="gallery-image"
            />
          ))}
        </div>
        <button onClick={toggleShowMore} className="show-more-btn">
          {showAll ? "Show Less" : "Show More"}
        </button>
      </div>

      <div className="team-section">
        <h1 className="team-heading">
          Meet Our <span className="profileSpan">Team</span>
        </h1>
        <div className="Profilecards-container">
          {teamMembers.map((member) => (
            <div className="Profilecard" key={member.id}>
              <img
                src={member.image}
                alt={member.name}
                className="card-image"
              />
              <div className="card-info">
                <h2 className="card-name">{member.name}</h2>
                <p className="card-role">{member.role}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="customer-feedback">
        <h2 className="feedback-heading">Customer Feedback</h2>
        <div className="review-cards">
          {reviews.slice(currentSlide, currentSlide + 3).map((review) => (
            <div key={review.id} className="review-card">
              <div className="stars">
                {[...Array(review.stars)].map((_, index) => (
                  <span key={index} className="star">
                    &#9733;
                  </span>
                ))}
              </div>
              <p className="description">{review.description}</p>
              <img src={review.image} alt={review.name} className="avatar" />
              <p className="name">{review.name}</p>
            </div>
          ))}
        </div>
        <div className="controls">
          <button className="prev" onClick={handlePrevSlide}>
            &lt;
          </button>
          <button className="next" onClick={handleNextSlide}>
            &gt;
          </button>
        </div>
      </div>

      <div className="Contactmain-container">
        <div className="Contactleft-container">
          <div className="Contactcontent">
            <h2>BOOK YOUR APPOINTMENT TODAY</h2>
            <p>AND TALK WITH US</p>
          </div>
        </div>
        <div className="Contactright-container">
          <form>
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="John Doe"
              required
            />

            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="example@gmail.com"
              required
            />

            <label htmlFor="service">Select Service:</label>
            <select id="service" name="service" required>
              <option value="">Select an option</option>
              <option value="service1">Service 1</option>
              <option value="service2">Service 2</option>
              <option value="service3">Service 3</option>
            </select>

            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              rows="4"
              placeholder="Message...."
              required
            ></textarea>

            <button className="Contactbutton" type="submit">
              Book Appointment
            </button>
          </form>
        </div>
      </div>
      <div className="contactBtn-container">
        <p>Choose Your Plan</p>
        <div>
          <Link to="/explore">
            <button>Click Here</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
