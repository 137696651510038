import React, { useState } from "react";
import "./Explor.css";
import { Link, useNavigate } from "react-router-dom";
import map_wrapper from "../../../Assets/map_wrapper.png";

function Explore() {
  const faqData = [
    {
      question: "What resources do you provide?",
      answer:
        "We provide a wide range of resources including articles, tutorials, videos, and more.",
    },
    {
      question: "How are you?",
      answer: "Fine.",
    },
    {
      question: "How can I contact support?",
      answer:
        "You can contact our support team via email at support@example.com or through our online chat system.",
    },
  ];
  const [openIndex, setOpenIndex] = useState(null);
  const toggleAnswer = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const handleLocationClick = () => {
    window.location.href =
      "https://www.google.com/maps/dir/-37.71283,144.9721985/17+Calverton+Ct,+Brassall+QLD+4305/@-32.4946939,143.5461213,6z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x6b96b6a794c8eb3b:0xf9c09f9319c678fa!2m2!1d152.7311734!2d-27.5876025?entry=ttu";
  };

  return (
    <>
      <div className="exploreBtn-container">
        <div className="explore-content">
          <h1>Divine Services</h1>
          <p>
            We are committed to delivering high-quality, efficient, and reliable
            services
          </p>

          {/* <button className="explore-button">Explore</button> */}
        </div>
      </div>

      {/* <section className="card-section">
        <div className="card3">
          <div className="card-info">
            <span className="card-number">1</span>
            <h2 className="card-title">Our Mission</h2>
            <p className="card-description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
          </div>
        </div>
        <div className="card3">
          <div className="card-info">
            <span className="card-number">2</span>
            <h2 className="card-title">Our Team</h2>
            <p className="card-description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
          </div>
        </div>
        <div className="card3">
          <div className="card-info">
            <span className="card-number">3</span>
            <h2 className="card-title">Testimonials</h2>
            <p className="card-description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
          </div>
        </div>
      </section> */}

      <section className="services">
        <div className="services-container">
          <h1>Our Services</h1>
          <p>
            We offer a comprehensive range of solutions tailored to meet your
            business needs. Our services include employee management, client
            management, payroll systems, and drafting of service agreements.
            Each service is designed to streamline operations, enhance
            productivity, and ensure compliance with industry standards.
          </p>
          <div className="cards-container">
            <div className="card">
              <h2>Employee Management</h2>
              <p>
                A system or process designed to manage employees within an
                organization.
              </p>
            </div>
            <div className="card">
              <h2>Client Management</h2>
              <p>
                A system or strategy focused on managing and nurturing
                relationships with clients.
              </p>
            </div>
            <div className="card">
              <h2>Payroll System</h2>
              <p>
                A process designed to manage the compensation of employees. .
              </p>
            </div>
            <div className="card">
              <h2>Services Agreements</h2>
              <p>
                Contracts between a service provider and a client that outline
                the terms and conditions of the services to be performed.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="faq-section">
        <div className="faq-header">
          <h1>Frequently Asked Questions</h1>
        </div>
        {faqData.map((item, index) => (
          <div className="faq-item" key={index}>
            <div className="question" onClick={() => toggleAnswer(index)}>
              <h3>{item.question}</h3>
              <div className={`icon ${openIndex === index ? "rotate" : ""}`}>
                +
              </div>
            </div>
            {openIndex === index && (
              <div className="answer">
                <p>{item.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div> */}

      <div className="explore-container">
        <p>Get Involved </p>
        <div>
          <Link to="/register">
            <button>Click to Join us</button>
          </Link>
        </div>
      </div>
      <section className="contact-section">
        <div className="contact-info">
          <div className="location" onClick={handleLocationClick}>
            <img
              src={map_wrapper}
              alt="Location of Place"
              className="location-image"
            />
          </div>
          <div className="contact-details">
            <h2>Contact Us</h2>
            <div className="address">
              <strong>Address:</strong>
              17 Calverton court Brassall Qld 4305 Australia
            </div>
            <div className="phone">
              <strong>Phone No:</strong> 1300 358 044
            </div>
            {/* <div className="email">
              <strong>Email:</strong> example@gmail.com
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default Explore;
