import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import logo from "../../Assets/logo.png";
import { MdEmail } from "react-icons/md";
import { BsCalendar2DateFill } from "react-icons/bs";
import {
  FaFacebook,
  FaTwitter,
  FaInstagramSquare,
  FaYoutube,
  FaTiktok,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-row">
        <div className="footer-col">
          <div className="footer-logo">
            <img src={logo} alt="Logo" />
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
              ullamcorper luctus massa, id lobortis eros ullamcorper vitae.Lorem
              ipsum dolor sit amet, consectetur adipiscing elit.
            </p> */}
            <div className="subscribe">
              <p>Subscribe (Get Latest Offers)</p>
              <div className="email-input">
                <MdEmail className="email-icon" />
                <input type="email" placeholder="Enter Your Email" />
              </div>
              {/* You can add the button for subscribe here if needed */}
            </div>
          </div>
        </div>
        <div className="footer-col">
          <div>
            <h2 className="footerHeading">Recent News</h2>
            <div className="news-item">
              <h4>Title of the blog goes here - max two lines</h4>
              <p>
                <i className="far fa-calendar-alt">
                  <BsCalendar2DateFill />
                </i>{" "}
                01-31-2024
              </p>
            </div>
            <div className="news-item">
              <h4>Title of the blog goes here - max two lines</h4>
              <p>
                <i className="far fa-calendar-alt">
                  <BsCalendar2DateFill />
                </i>{" "}
                01-31-2024
              </p>
            </div>
          </div>
        </div>
        <div className="footer-col">
          <div style={{ textAlign: "center" }}>
            <h2 className="footerHeading">Links</h2>
            <ul style={{ fontWeight: "600" }}>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/features">Features</Link>
              </li>
              <li>
                <Link to="/solution">Solution</Link>
              </li>
              <li>
                <Link to="/pricing">Pricing</Link>
              </li>
              <li>
                <Link to="/blogs">Blogs</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-col">
          <div>
            <h2 className="footerHeading">
              {" "}
              <span className="footerSpan">Get in</span> Touch
            </h2>
            {/* <p>
              Lorem ipsum dolor sit amet consectetur. Felis aliquet diam
              facilisi odio et ultrices.
            </p> */}
            <div className="form-row">
              <input type="text" placeholder="First Name" />
              <input type="text" placeholder="Last Name" />
            </div>
            <div className="form-row">
              <input type="email" placeholder="Email" />
              <input type="tel" placeholder="Phone" />
            </div>
            <button className="footerButton">Send</button>
          </div>
        </div>
      </div>
      <hr></hr>

      <div style={{ display: "flex", alignItems: "center" }}>

        <p className="footerPara">Powered By - Support Care - Copyright 2024</p>
        
        <div className="footericonHeader" >
          <a href="#">
            <i className="footerIcon">
              <FaFacebook />
            </i>
          </a>
          <a href="#">
            <i className="footerIcon">
              <FaTwitter />
            </i>
          </a>
          <a href="#">
            <i className="footerIcon">
              <FaInstagramSquare />
            </i>
          </a>
          <a href="#">
            <i className="footerIcon">
              <FaTiktok />
            </i>
          </a>
          <a href="#">
            <i className="footerIcon">
              <FaYoutube />
            </i>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
